import React, { useState, useEffect } from 'react';
import { RefreshCw } from 'lucide-react';

const REFRESH_COOLDOWN = 30 * 60 * 1000; // 30 minutes in milliseconds

const formatTimeLeft = (ms) => {
  const minutes = Math.floor(ms / 60000);
  const seconds = Math.floor((ms % 60000) / 1000);
  return {
    minutes,
    seconds,
    text: `${minutes}m ${seconds}s`,
    nextUpdate: new Date(Date.now() + ms).toLocaleTimeString('en-US', { 
      hour: 'numeric', 
      minute: 'numeric',
      hour12: true 
    })
  };
};

const RefreshButton = ({ onRefresh, serviceName }) => {
  const [lastRefresh, setLastRefresh] = useState(() => {
    const stored = localStorage.getItem(`lastScheduleRefresh_${serviceName}`);
    return stored ? parseInt(stored) : 0;
  });
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      const now = Date.now();
      const timeSinceLastRefresh = now - lastRefresh;
      const remainingTime = Math.max(0, REFRESH_COOLDOWN - timeSinceLastRefresh);
      setTimeLeft(remainingTime);
    }, 1000);

    return () => clearInterval(timer);
  }, [lastRefresh, serviceName]);

  const handleRefresh = () => {
    const now = Date.now();
    localStorage.setItem(`lastScheduleRefresh_${serviceName}`, now.toString());
    setLastRefresh(now);
    onRefresh();
  };

  const isDisabled = timeLeft > 0;
  const timeInfo = formatTimeLeft(timeLeft);

  return (
    <button
      onClick={handleRefresh}
      disabled={isDisabled}
      className={`flex items-center gap-2 px-4 py-2 rounded transition-colors ${
        isDisabled 
          ? 'bg-gray-300 dark:bg-gray-700 dark:text-gray-300 cursor-not-allowed' 
          : 'bg-blue-500 hover:bg-blue-600 text-white'
      }`}
      title={isDisabled ? `Next update available at ${timeInfo.nextUpdate}` : 'Refresh schedule data'}
    >
      <RefreshCw 
        className={`h-5 w-5 ${!isDisabled && 'animate-spin'}`}
      />
      {isDisabled 
        ? `Next update in ${timeInfo.text}`
        : 'Refresh Schedule'
      }
    </button>
  );
};

export default RefreshButton;