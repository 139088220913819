const API_KEY = process.env.REACT_APP_MAERSK_API_KEY;
const API_BASE_URL = 'https://api.maersk.com/schedules';
const SAECS_SERVICE_CODE = '278';
const VESSELS_PER_GRID = 8;

// Helper function to add delay between API calls
const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

// Helper function to handle API rate limiting
const rateLimitedFetch = async (url, options, retryCount = 3) => {
  try {
    const response = await fetch(url, {
      ...options,
      headers: {
        'Consumer-Key': API_KEY,
        'Accept': 'application/json',
        ...options?.headers
      }
    });

    if (response.status === 429 && retryCount > 0) {
      console.log('Rate limit hit, waiting before retry...');
      await delay(1000); // Wait 1 second before retry
      return rateLimitedFetch(url, options, retryCount - 1);
    }

    if (!response.ok) {
      throw new Error(`API error: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error('Fetch error:', error);
    if (retryCount > 0) {
      console.log(`Retrying... ${retryCount} attempts left`);
      await delay(1000);
      return rateLimitedFetch(url, options, retryCount - 1);
    }
    throw error;
  }
};

// Helper to get date range
const getDateRange = () => {
  const today = new Date();
  const startDate = new Date(today);
  startDate.setDate(today.getDate() - 31); // 4 weeks back
  return {
    startDate: startDate.toISOString().split('T')[0],
    dateRange: 'P24W'  // Extended to 24 weeks (was P8W)
  };
};

// Helper function to separate rotations
const separateRotations = (vesselSchedules) => {
  // Sort all vessels by departure time
  const sortedSchedules = vesselSchedules.sort((a, b) => new Date(a.departureTime) - new Date(b.departureTime));

  return {
    currentRotation: sortedSchedules.slice(0, VESSELS_PER_GRID).map(vs => vs.vessel),
    upcomingRotation: sortedSchedules.slice(VESSELS_PER_GRID, VESSELS_PER_GRID * 2).map(vs => vs.vessel)
  };
};

// Enhanced function to fetch discharge schedules for multiple vessels
const fetchDischargeSchedules = async (vessels, dateParams) => {
  const schedules = {};
  
  // Process vessels sequentially to respect rate limits
  for (const vessel of vessels) {
    try {
      console.log(`Fetching schedule for vessel: ${vessel.name} (${vessel.vesselIMO})`);
      const response = await rateLimitedFetch(
        `${API_BASE_URL}/vessel-schedules?vesselIMONumber=${vessel.vesselIMO}&carrierCodes=MAEU&startDate=${dateParams.startDate}&dateRange=${dateParams.dateRange}`
      );
      
      if (response) {
        schedules[vessel.name] = response;
      }
      
      // Add small delay between requests to help prevent rate limiting
      await delay(200);
    } catch (error) {
      console.error(`Error fetching schedule for vessel ${vessel.name}:`, error);
      schedules[vessel.name] = null;
    }
  }
  
  return schedules;
};

const fetchSAECSSchedules = async () => {
  try {
    // Get date range for all API calls
    const dateParams = getDateRange();
    
    // Fetch Cape Town data first to get vessel list
    const capeTownData = await rateLimitedFetch(
      `${API_BASE_URL}/port-calls?countryCode=ZA&cityName=Cape Town&carrierCodes=MAEU&startDate=${dateParams.startDate}&dateRange=${dateParams.dateRange}`
    );

    const vesselSchedules = [];
    if (capeTownData?.portCalls) {
      capeTownData.portCalls.forEach(portCall => {
        portCall.facilityCalls.forEach(facilityCall => {
          const transport = facilityCall.transport;
          if (transport?.outboundService?.carrierServiceCode === SAECS_SERVICE_CODE) {
            const departure = facilityCall.callSchedules.find(s => s.transportEventTypeCode === 'DEPA');
            if (departure) {
              vesselSchedules.push({
                vessel: {
                  name: transport.vessel.vesselName,
                  voyage: transport.outboundService.carrierVoyageNumber,
                  code: transport.vessel.carrierVesselCode,
                  vesselIMO: transport.vessel.vesselIMONumber
                },
                departureTime: new Date(departure.classifierDateTime)
              });
            }
          }
        });
      });
    }

    // Separate the rotations
    const { currentRotation, upcomingRotation } = separateRotations(vesselSchedules);

    // Fetch load port schedules in parallel
    const [durbanData, peData] = await Promise.all([
      rateLimitedFetch(`${API_BASE_URL}/port-calls?countryCode=ZA&cityName=Durban&carrierCodes=MAEU&startDate=${dateParams.startDate}&dateRange=${dateParams.dateRange}`),
      rateLimitedFetch(`${API_BASE_URL}/port-calls?countryCode=ZA&cityName=Port Elizabeth&carrierCodes=MAEU&startDate=${dateParams.startDate}&dateRange=${dateParams.dateRange}`)
    ]);

    // Fetch discharge schedules for all vessels
    console.log('Fetching discharge schedules for all vessels...');
    const allVessels = [...currentRotation, ...upcomingRotation];
    const dischargeSchedules = await fetchDischargeSchedules(allVessels, dateParams);

    // Process current rotation
    const currentLoadPorts = [
      { name: 'Durban', schedules: processPortSchedules(durbanData, currentRotation) },
      { name: 'Port Elizabeth', schedules: processPortSchedules(peData, currentRotation) },
      { name: 'Cape Town', schedules: processPortSchedules(capeTownData, currentRotation) }
    ];

    const currentDischargePorts = [
      'London Gateway', 
      'Rotterdam', 
      'Bremerhaven', 
      'Algeciras - ML Terminal', 
      'Algeciras - TTI'
    ].map(portName => ({
      name: portName,
      schedules: currentRotation.map(vessel => {
        const vesselSchedule = dischargeSchedules[vessel.name];
        return processDischargeSchedule(vesselSchedule, portName, vessel);
      })
    }));

    // Process upcoming rotation
    const upcomingLoadPorts = [
      { name: 'Durban', schedules: processPortSchedules(durbanData, upcomingRotation) },
      { name: 'Port Elizabeth', schedules: processPortSchedules(peData, upcomingRotation) },
      { name: 'Cape Town', schedules: processPortSchedules(capeTownData, upcomingRotation) }
    ];

    const upcomingDischargePorts = [
      'London Gateway', 
      'Rotterdam', 
      'Bremerhaven', 
      'Algeciras - ML Terminal', 
      'Algeciras - TTI'
    ].map(portName => ({
      name: portName,
      schedules: upcomingRotation.map(vessel => {
        const vesselSchedule = dischargeSchedules[vessel.name];
        return processDischargeSchedule(vesselSchedule, portName, vessel);
      })
    }));

    // Return both rotations
    return {
      current: {
        vessels: currentRotation,
        loadPorts: currentLoadPorts,
        dischargePorts: currentDischargePorts
      },
      upcoming: {
        vessels: upcomingRotation,
        loadPorts: upcomingLoadPorts,
        dischargePorts: upcomingDischargePorts
      }
    };

  } catch (error) {
    console.error('Error fetching schedules:', error);
    throw error;
  }
};

// Helper function to process port schedules with voyage validation
const processPortSchedules = (portData, vessels) => {
  const schedules = vessels.map(() => ({ 
    eta: { text: 'N/A', isActual: false, rawDateTime: null }, 
    etd: { text: 'N/A', isActual: false, rawDateTime: null } 
  }));

  if (!portData?.portCalls) return schedules;

  portData.portCalls.forEach(portCall => {
    portCall.facilityCalls.forEach(facilityCall => {
      const transport = facilityCall.transport;
      const vesselIndex = vessels.findIndex(v => {
        // Match both vessel name and voyage number
        const matchesVessel = v.name === transport.vessel.vesselName;
        const matchesVoyage = 
          transport.outboundService?.carrierVoyageNumber === v.voyage ||
          transport.inboundService?.carrierVoyageNumber === v.voyage;
        return matchesVessel && matchesVoyage;
      });
      
      if (vesselIndex !== -1 && 
          (transport.inboundService?.carrierServiceCode === SAECS_SERVICE_CODE || 
           transport.outboundService?.carrierServiceCode === SAECS_SERVICE_CODE)) {
        
        const arrival = facilityCall.callSchedules.find(s => s.transportEventTypeCode === 'ARRI');
        const departure = facilityCall.callSchedules.find(s => s.transportEventTypeCode === 'DEPA');
        
        schedules[vesselIndex] = {
          eta: formatDate(arrival?.dateTime || arrival?.classifierDateTime, arrival?.eventClassifierCode),
          etd: formatDate(departure?.dateTime || departure?.classifierDateTime, departure?.eventClassifierCode)
        };
      }
    });
  });

  return schedules;
};

// Helper function to process discharge schedule
const processDischargeSchedule = (dischargeData, portName, vesselData) => {
  if (!dischargeData?.vesselCalls) {
    return { eta: { text: 'N/A', isActual: false, rawDateTime: null }, etd: { text: 'N/A', isActual: false, rawDateTime: null } };
  }
  
  // Find the correct port call that matches both port and voyage number
  const call = dischargeData.vesselCalls.find(call => {
    // First check if this is the right port
    const isCorrectPort = portName.includes('Algeciras') 
      ? (portName.includes('ML Terminal') 
          ? !call.facility.locationName.includes('TTI') 
          : call.facility.locationName.includes('TTI'))
        && call.facility.cityName === 'Algeciras'
      : call.facility.cityName === portName;

    if (!isCorrectPort) return false;

    // Then check if this matches our export voyage number
    const matchesVoyage = 
      call.transport?.outboundService?.carrierVoyageNumber === vesselData.voyage ||
      call.transport?.inboundService?.carrierVoyageNumber === vesselData.voyage;

    return isCorrectPort && matchesVoyage;
  });

  if (!call) {
    return { eta: { text: 'N/A', isActual: false, rawDateTime: null }, etd: { text: 'N/A', isActual: false, rawDateTime: null } };
  }

  const arrival = call.callSchedules.find(s => s.transportEventTypeCode === 'ARRI');
  const departure = call.callSchedules.find(s => s.transportEventTypeCode === 'DEPA');

  // Check if this is the last discharge port (London Gateway)
  const isLastDischargePort = portName === 'London Gateway';
  
  // If this is the last discharge port and we have an actual arrival
  if (isLastDischargePort && arrival?.eventClassifierCode === 'ACT') {
    const arrivalDate = new Date(arrival.classifierDateTime);
    const oneDayAfter = new Date(arrivalDate);
    oneDayAfter.setDate(oneDayAfter.getDate() + 1);
    
    // If it's been less than a day since actual arrival, keep the vessel
    if (new Date() < oneDayAfter) {
      vesselData.keepVisible = true;
    }
  }

  return {
    eta: formatDate(arrival?.dateTime || arrival?.classifierDateTime, arrival?.eventClassifierCode),
    etd: formatDate(departure?.dateTime || departure?.classifierDateTime, departure?.eventClassifierCode)
  };
};

// Date formatting helper
const formatDate = (dateTimeStr, eventClassifierCode) => {
  if (!dateTimeStr) return { text: 'N/A', isActual: false, rawDateTime: null };
  const date = new Date(dateTimeStr);
  return {
    text: date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: '2-digit'
    }).replace(',', ''),
    rawDateTime: dateTimeStr,
    isActual: eventClassifierCode === 'ACT'
  };
};

// Normalize string for comparison
const normalizeString = (str) => {
    if (!str) return '';
    // Remove all non-alphanumeric characters and convert to lowercase
    return str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
};

// Normalize vessel name
const normalizeVesselName = (name) => {
    if (!name) return '';
    
    // Remove any whitespace and convert to lowercase
    name = name.trim().toLowerCase();
    
    // Remove common prefixes and suffixes
    const prefixes = ['mv', 'mv.', 'ss', 'ss.'];
    for (const prefix of prefixes) {
        if (name.startsWith(prefix + ' ')) {
            name = name.slice(prefix.length).trim();
        }
    }
    
    // Remove special characters and extra spaces
    name = name.replace(/[^a-z0-9]/g, '');
    
    // Common carrier abbreviations
    const carrierMappings = {
        'maersk': 'msk',
        'mediterranean shipping company': 'msc',
        'cma cgm': 'cmacgm'
    };
    
    // Apply carrier mappings
    for (const [full, abbrev] of Object.entries(carrierMappings)) {
        name = name.replace(full, abbrev);
    }
    
    return name;
};

// Normalize voyage number
const normalizeVoyage = (voyage) => {
    if (!voyage) return '';
    
    // Remove any whitespace and convert to uppercase
    voyage = voyage.trim().toUpperCase();
    
    // Handle multiple voyages (e.g., "NZ444A NZ449R" -> "NZ449R")
    if (voyage.includes(' ')) {
        const voyages = voyage.split(' ');
        // Take the last voyage as it's typically the export voyage
        voyage = voyages[voyages.length - 1];
    }
    
    // Handle split voyages with slashes first (e.g., "445S/449N" -> "449N")
    if (voyage.includes('/')) {
        // Special cases: don't split if it's just a suffix like S/N or W/E
        if (!voyage.endsWith('/N') && !voyage.endsWith('/E')) {
            const voyages = voyage.split('/');
            // Take the last part as it's typically the export voyage
            voyage = voyages[voyages.length - 1];
        }
    }
    
    // Now handle suffix variations
    voyage = voyage
        .replace('S/N', 'N')
        .replace('S/', 'N')
        .replace('N/S', 'N')
        .replace('W/E', 'E')
        .replace('W/', 'E');
    
    // Remove any remaining special characters
    voyage = voyage.replace(/[^A-Z0-9]/g, '');
    
    // Handle common suffix conversions
    const suffixMappings = {
        'S': 'N',  // Convert S to N for northbound
        'W': 'E'   // Convert W to E as per example
    };
    
    // Check if voyage ends with any of the suffixes and convert
    for (const [from, to] of Object.entries(suffixMappings)) {
        if (voyage.endsWith(from)) {
            voyage = voyage.slice(0, -1) + to;
        }
    }
    
    // If it's just a number, append N (default to northbound)
    if (/^\d+$/.test(voyage)) {
        voyage = voyage + 'N';
    }
    
    return voyage;
};

// Cache for stack dates
let stackDatesCache = null;
let lastFetchTime = null;
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

// Fetch all stack dates from the JSON file
export const fetchAllStackDates = async () => {
    try {
        const response = await fetch('/stack_dates.json');
        const json = await response.json();
        return json.data || [];
    } catch (error) {
        console.error('Error loading stack dates:', error);
        return [];
    }
};

// Fetch stack dates for a vessel
export const fetchStackDates = async (vesselName, voyage) => {
    try {
        console.log('Fetching stack dates for:', { vesselName, voyage });
        
        // Get all stack dates
        const allDates = await fetchAllStackDates();
        if (!allDates || allDates.length === 0) {
            console.log('No stack dates available');
            return [];
        }
        
        // Normalize search terms
        const normalizedVessel = normalizeVesselName(vesselName);
        const normalizedVoyage = normalizeVoyage(voyage);
        
        console.log('Normalized search terms:', {
            normalizedVessel,
            normalizedVoyage,
            originalVessel: vesselName,
            originalVoyage: voyage
        });
        
        // Find exact matches first
        const exactMatches = allDates.filter(date => {
            if (!date || !date.vessel_name || !date.voyage) {
                console.log('Invalid date entry:', date);
                return false;
            }
            
            const dateNormalizedVessel = normalizeVesselName(date.vessel_name);
            const dateNormalizedVoyage = normalizeVoyage(date.voyage);
            
            console.log('Comparing with:', {
                dateVessel: date.vessel_name,
                dateVoyage: date.voyage,
                normalizedDateVessel: dateNormalizedVessel,
                normalizedDateVoyage: dateNormalizedVoyage
            });
            
            return dateNormalizedVessel === normalizedVessel && 
                   dateNormalizedVoyage === normalizedVoyage;
        });
        
        // If we have exact matches, return those
        if (exactMatches.length > 0) {
            console.log('Found exact matches:', exactMatches);
            return exactMatches;
        }
        
        // Try fuzzy matching with more lenient criteria
        const fuzzyMatches = allDates.filter(date => {
            if (!date || !date.vessel_name || !date.voyage) {
                return false;
            }
            
            const dateNormalizedVessel = normalizeVesselName(date.vessel_name);
            const dateNormalizedVoyage = normalizeVoyage(date.voyage);
            
            // For vessel similarity:
            // 1. Check if one contains the other
            // 2. Check if they share a significant portion of characters
            const vesselSimilar = (
                dateNormalizedVessel.includes(normalizedVessel) || 
                normalizedVessel.includes(dateNormalizedVessel) ||
                calculateSimilarity(dateNormalizedVessel, normalizedVessel) > 0.8
            );
            
            // For voyage similarity:
            // 1. Check if numeric parts match
            // 2. Check if they're exactly equal
            const numericVoyage = normalizedVoyage.replace(/[^0-9]/g, '');
            const numericDateVoyage = dateNormalizedVoyage.replace(/[^0-9]/g, '');
            const voyageSimilar = (
                numericVoyage === numericDateVoyage ||
                dateNormalizedVoyage === normalizedVoyage
            );
            
            const isMatch = vesselSimilar && voyageSimilar;
            
            if (isMatch) {
                console.log('Found fuzzy match:', {
                    stackVessel: date.vessel_name,
                    normalizedStackVessel: dateNormalizedVessel,
                    stackVoyage: date.voyage,
                    normalizedStackVoyage: dateNormalizedVoyage,
                    similarity: calculateSimilarity(dateNormalizedVessel, normalizedVessel)
                });
            }
            
            return isMatch;
        });
        
        console.log('Final matches:', fuzzyMatches);
        
        // Sort by stack opening date
        return fuzzyMatches.sort((a, b) => 
            new Date(b.stack_opening) - new Date(a.stack_opening)
        );
    } catch (error) {
        console.error('Error fetching stack dates:', error);
        return null;
    }
};

// Helper function to calculate string similarity (Levenshtein distance)
const calculateSimilarity = (str1, str2) => {
    const longer = str1.length > str2.length ? str1 : str2;
    const shorter = str1.length > str2.length ? str2 : str1;
    
    if (longer.length === 0) return 1.0;
    
    const costs = [];
    for (let i = 0; i <= longer.length; i++) {
        let lastValue = i;
        for (let j = 0; j <= shorter.length; j++) {
            if (i === 0) {
                costs[j] = j;
            } else if (j > 0) {
                let newValue = costs[j - 1];
                if (longer[i - 1] !== shorter[j - 1]) {
                    newValue = Math.min(
                        Math.min(newValue, lastValue),
                        costs[j]
                    ) + 1;
                }
                costs[j - 1] = lastValue;
                lastValue = newValue;
            }
        }
        if (i > 0) costs[shorter.length] = lastValue;
    }
    
    return (longer.length - costs[shorter.length]) / longer.length;
};

// Test function to verify vessel and voyage matching
const testStackDateMatching = async () => {
    const testCases = [
        { vessel: 'MSC Clarita III', voyage: 'ZA449A' },
        { vessel: 'Santa Cruz', voyage: '244S/N' },
        { vessel: 'MSC Yashi B', voyage: 'NZ444A NZ449R' },
        { vessel: 'COSCO Aqabqa', voyage: '084W 084E' },
        { vessel: 'Santa Isabel', voyage: '244S/N' },
        { vessel: 'Nele Maersk', voyage: '445S/449N' },
        { vessel: 'CMA CGM Davao', voyage: '0WY2FS1MA 0WY2GN1MA' },
        { vessel: 'Ital Unica', voyage: '181W/E' },
        { vessel: 'MSC Krystal', voyage: 'NZ450R' },
        { vessel: 'Maersk Cap Carmel', voyage: '450E/452W' }
    ];

    // First fetch all stack dates once
    const allDates = await fetchAllStackDates();
    console.log('Total stack dates in database:', allDates.length);
    
    console.log('\n=== Starting Matching Tests ===\n');
    
    for (const testCase of testCases) {
        console.log(`\n🚢 Testing vessel: ${testCase.vessel}`);
        console.log(`📝 Voyage: ${testCase.voyage}`);
        
        const normalizedVessel = normalizeVesselName(testCase.vessel);
        const normalizedVoyage = normalizeVoyage(testCase.voyage);
        
        console.log('Normalized to:', {
            vessel: normalizedVessel,
            voyage: normalizedVoyage
        });
        
        // Find matches in the already fetched stack dates
        const matches = allDates.filter(date => {
            const dateNormalizedVessel = normalizeVesselName(date.vessel_name);
            const dateNormalizedVoyage = normalizeVoyage(date.voyage);
            
            const vesselMatch = dateNormalizedVessel === normalizedVessel;
            const voyageMatch = dateNormalizedVoyage === normalizedVoyage;
            
            if (vesselMatch || voyageMatch) {
                console.log('Potential match found:', {
                    stackVessel: date.vessel_name,
                    normalizedStackVessel: dateNormalizedVessel,
                    stackVoyage: date.voyage,
                    normalizedStackVoyage: dateNormalizedVoyage,
                    matched: vesselMatch ? 'vessel' : 'voyage'
                });
            }
            
            return vesselMatch && voyageMatch;
        });
        
        if (matches.length > 0) {
            console.log('✅ MATCH FOUND!');
            matches.forEach((match, index) => {
                console.log(`Match ${index + 1}:`, {
                    vessel: match.vessel_name,
                    voyage: match.voyage,
                    stack_opening: match.stack_opening,
                    stack_closing: match.stack_closing,
                    terminal: match.terminal
                });
            });
        } else {
            console.log('❌ NO MATCH FOUND');
        }
    }
};

// Export for testing
export { testStackDateMatching };

export { fetchSAECSSchedules };