import React, { useState, useEffect } from 'react';
import { fetchSAECSSchedules, fetchStackDates, testStackDateMatching } from '../services/maerskApi';
import ExcelExport from './ExcelExport';
import RefreshButton from './RefreshButton';
import LoadingIndicator from './LoadingIndicator';
import { ScheduleCache } from '../services/ScheduleCache';
import ScheduleGrid from './ScheduleGrid';

const VESSELS_PER_GRID = 8;

const formatDateWithTime = (date) => {
  if (!date) return 'N/A';
  const dateObj = new Date(date);
  // Convert to local timezone for display
  return dateObj.toLocaleString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false // Use 24-hour format
  });
};

const calculateSICutoff = (etd) => {
  if (!etd) return null;
  const cutoffDate = new Date(etd);
  cutoffDate.setHours(cutoffDate.getHours() - 72);
  return cutoffDate.toISOString();
};

const getTerminalShortName = (terminal) => {
  if (!terminal) return '';
  // Remove underscores and normalize the string
  const normalizedTerminal = terminal.replace(/_/g, ' ');
  
  if (normalizedTerminal.includes('CAPE TOWN CONTAINER') || normalizedTerminal === 'CAPE_TOWN_CONTAINER') return 'CTCT';
  if (normalizedTerminal.includes('CTP MULTI TERMINAL')) return 'CTP MT';
  return terminal;
};

const getStatusColor = (status) => {
  switch (status) {
    case 'FIRM':
      return 'text-green-500';
    case 'CLOSED':
      return 'text-red-500';
    case 'PROV':
      return 'text-yellow-500';
    default:
      return 'text-blue-500';
  }
};

const formatDateTime = (date) => {
  if (!date) return 'N/A';
  const dateObj = new Date(date);
  // Convert to local timezone for display
  return dateObj.toLocaleString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false // Use 24-hour format
  });
};

const VesselHeader = ({ vessel, loadPorts, vesselIndex }) => {
  const [stackDates, setStackDates] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Find the raw schedules from the port data
  const durbanPort = loadPorts?.find(p => p.name === 'Durban');
  const cptPort = loadPorts?.find(p => p.name === 'Cape Town');

  // Get the raw departure times
  const durbanDeparture = durbanPort?.schedules[vesselIndex]?.etd?.rawDateTime;
  const cptDeparture = cptPort?.schedules[vesselIndex]?.etd?.rawDateTime;

  const durbanCutoff = calculateSICutoff(durbanDeparture);
  const cptCutoff = calculateSICutoff(cptDeparture);

  useEffect(() => {
    const getStackDates = async () => {
      if (vessel.vesselName || vessel.name) {
        setIsLoading(true);
        try {
          const vesselName = vessel.vesselName || vessel.name;
          const voyageNumber = vessel.voyageNumber || vessel.voyage;
          console.log('Fetching stack dates for:', { vesselName, voyageNumber });
          const dates = await fetchStackDates(vesselName, voyageNumber);
          setStackDates(dates);
        } catch (error) {
          console.error('Error fetching stack dates:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };
    getStackDates();
  }, [vessel]);

  // Function to check if stack dates should be available soon
  const shouldHaveStackDates = () => {
    if (!durbanDeparture && !cptDeparture) return false;

    // Get the earlier departure date
    const departureDates = [durbanDeparture, cptDeparture].filter(Boolean).map(date => new Date(date));
    if (departureDates.length === 0) return false;

    const earliestDeparture = new Date(Math.min(...departureDates));
    const now = new Date();

    // Stack dates are typically available 2-3 weeks before departure
    const threeWeeksFromNow = new Date();
    threeWeeksFromNow.setDate(threeWeeksFromNow.getDate() + 21);

    return earliestDeparture <= threeWeeksFromNow;
  };

  return (
    <div>
      <div className="px-2 py-1 font-medium text-gray-900 dark:text-white">
        {vessel.vesselName || vessel.name}<br/>{vessel.voyageNumber || vessel.voyage}
      </div>
    </div>
  );
};

const ServiceScheduleView = () => {
  const [scheduleData, setScheduleData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  useEffect(() => {
    window.testStackDateMatching = testStackDateMatching;
  }, []);

  const loadScheduleData = async (clearCache = true) => {
    try {
      setIsLoading(true);

      if (!clearCache) {
        const cachedData = ScheduleCache.getCache('SAECS');
        if (cachedData) {
          setScheduleData(cachedData);
          setError(null);
          setIsLoading(false);
          return;
        }
      } else {
        ScheduleCache.clearCache('SAECS');
      }

      const data = await fetchSAECSSchedules();

      if (data.current && data.current.vessels) {
        data.current.vessels = data.current.vessels.slice(0, VESSELS_PER_GRID);
        data.current.loadPorts = data.current.loadPorts.map(port => ({
          ...port,
          schedules: port.schedules.slice(0, VESSELS_PER_GRID)
        }));
        data.current.dischargePorts = data.current.dischargePorts.map(port => ({
          ...port,
          schedules: port.schedules.slice(0, VESSELS_PER_GRID)
        }));
      }

      if (data.upcoming && data.upcoming.vessels) {
        data.upcoming.vessels = data.upcoming.vessels.slice(0, VESSELS_PER_GRID);
        data.upcoming.loadPorts = data.upcoming.loadPorts.map(port => ({
          ...port,
          schedules: port.schedules.slice(0, VESSELS_PER_GRID)
        }));
        data.upcoming.dischargePorts = data.upcoming.dischargePorts.map(port => ({
          ...port,
          schedules: port.schedules.slice(0, VESSELS_PER_GRID)
        }));
      }

      ScheduleCache.setCache('SAECS', data);
      setScheduleData(data);
      setError(null);
    } catch (error) {
      console.error('Error loading schedule data:', error);
      setError('Failed to load schedule data. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    loadScheduleData(false);
  }, []);

  if (isLoading) return <LoadingIndicator />;
  if (error) return (
    <div className="flex justify-center items-center h-64">
      <div className="text-red-600 dark:text-red-400">{error}</div>
    </div>
  );
  if (!scheduleData) return (
    <div className="flex justify-center items-center h-64">
      <div className="text-gray-600 dark:text-gray-300">No schedule data available</div>
    </div>
  );

  return (
    <div className="p-6 space-y-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">SAECS Service Schedule</h1>
        <div className="flex space-x-3">
          <RefreshButton onRefresh={() => loadScheduleData(true)} serviceName="SAECS" />
          <ExcelExport data={scheduleData} serviceName="SAECS" />
        </div>
      </div>

      <div className="space-y-4">
        <div className="mb-2">
          <h2 className="text-lg font-semibold text-blue-600 dark:text-blue-400">Primary Schedule</h2>
        </div>
        <ScheduleGrid
          vessels={scheduleData.current.vessels}
          loadPorts={scheduleData.current.loadPorts}
          dischargePorts={scheduleData.current.dischargePorts}
          VesselHeaderComponent={({ vessel, vesselIndex }) => (
            <VesselHeader
              vessel={vessel}
              loadPorts={scheduleData.current.loadPorts}
              vesselIndex={vesselIndex}
            />
          )}
        />
      </div>

      {scheduleData.upcoming.vessels.length > 0 && (
        <div className="space-y-4">
          <div className="mb-2">
            <h2 className="text-lg font-semibold text-green-600 dark:text-green-400">Extended Schedule</h2>
          </div>
          <ScheduleGrid
            vessels={scheduleData.upcoming.vessels}
            loadPorts={scheduleData.upcoming.loadPorts}
            dischargePorts={scheduleData.upcoming.dischargePorts}
            VesselHeaderComponent={({ vessel, vesselIndex }) => (
              <VesselHeader
                vessel={vessel}
                loadPorts={scheduleData.upcoming.loadPorts}
                vesselIndex={vesselIndex}
              />
            )}
          />
        </div>
      )}
    </div>
  );
};

export default ServiceScheduleView;