import React, { useState, useEffect } from 'react';
import { fetchMesawaSchedules } from '../services/MesawaApi';
import { ScheduleCache } from '../services/ScheduleCache';
import ExcelExport from './ExcelExport';
import LoadingIndicator from './LoadingIndicator';
import ScheduleGrid from './ScheduleGrid';
import RefreshButton from './RefreshButton';

const VESSELS_PER_GRID = 8;

const MesawaScheduleView = () => {
  const [scheduleData, setScheduleData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const loadSchedules = async (clearCache = true) => {
    try {
      setLoading(true);
      setError(null);
      
      if (!clearCache) {
        const cachedData = ScheduleCache.getCache('MESAWA');
        if (cachedData) {
          setScheduleData(cachedData);
          setError(null);
          setLoading(false);
          return;
        }
      } else {
        ScheduleCache.clearCache('MESAWA');
      }
      
      const data = await fetchMesawaSchedules();
      
      // Limit vessels per grid
      if (data.current && data.current.vessels) {
        data.current.vessels = data.current.vessels.slice(0, VESSELS_PER_GRID);
        data.current.loadPorts = data.current.loadPorts.map(port => ({
          ...port,
          schedules: port.schedules.slice(0, VESSELS_PER_GRID)
        }));
        data.current.dischargePorts = data.current.dischargePorts.map(port => ({
          ...port,
          schedules: port.schedules.slice(0, VESSELS_PER_GRID)
        }));
      }

      if (data.upcoming && data.upcoming.vessels) {
        data.upcoming.vessels = data.upcoming.vessels.slice(0, VESSELS_PER_GRID);
        data.upcoming.loadPorts = data.upcoming.loadPorts.map(port => ({
          ...port,
          schedules: port.schedules.slice(0, VESSELS_PER_GRID)
        }));
        data.upcoming.dischargePorts = data.upcoming.dischargePorts.map(port => ({
          ...port,
          schedules: port.schedules.slice(0, VESSELS_PER_GRID)
        }));
      }
      
      ScheduleCache.setCache('MESAWA', data);
      setScheduleData(data);
    } catch (err) {
      console.error('Error loading MESAWA schedules:', err);
      setError('Failed to load schedules. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadSchedules(false);
  }, []);

  if (loading) return <LoadingIndicator />;
  if (error) return (
    <div className="flex flex-col space-y-4 items-center justify-center h-64">
      <div className="text-red-600 dark:text-red-400">{error}</div>
      <button
        onClick={() => loadSchedules(true)}
        className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        Try Again
      </button>
    </div>
  );
  if (!scheduleData) return (
    <div className="flex justify-center items-center h-64">
      <div className="text-gray-600 dark:text-gray-300">No schedule data available</div>
    </div>
  );

  return (
    <div className="space-y-8">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-gray-900 dark:text-gray-100">MESAWA Service Schedule</h2>
        <div className="flex items-center space-x-4">
          <RefreshButton onRefresh={() => loadSchedules(true)} serviceName="MESAWA" />
          <ExcelExport data={scheduleData} serviceName="MESAWA" />
        </div>
      </div>
      
      <div className="space-y-8">
        {scheduleData?.current && (
          <div className="space-y-4">
            <div className="mb-2">
              <h2 className="text-lg font-semibold text-blue-600 dark:text-blue-400">Primary Schedule</h2>
            </div>
            <ScheduleGrid
              vessels={scheduleData.current.vessels}
              loadPorts={scheduleData.current.loadPorts}
              dischargePorts={scheduleData.current.dischargePorts}
            />
          </div>
        )}

        {scheduleData?.upcoming && (
          <div className="space-y-4">
            <div className="mb-2">
              <h2 className="text-lg font-semibold text-green-600 dark:text-green-400">Extended Schedule</h2>
            </div>
            <ScheduleGrid
              vessels={scheduleData.upcoming.vessels}
              loadPorts={scheduleData.upcoming.loadPorts}
              dischargePorts={scheduleData.upcoming.dischargePorts}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MesawaScheduleView;