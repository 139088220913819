import React, { useState, useEffect } from 'react';
import { fetchStackDates } from '../services/maerskApi';

const ScheduleDateCell = ({ dateInfo }) => (
  <td className={`
    px-2 py-2 text-center border-r dark:border-gray-600 whitespace-nowrap 
    ${dateInfo.isActual 
      ? 'text-green-600 dark:text-green-400 font-medium' 
      : 'text-gray-900 dark:text-gray-100'
    }`}
  >
    {dateInfo.text}
  </td>
);

const DefaultVesselHeader = ({ vessel }) => {
  const [stackDates, setStackDates] = useState(null);

  useEffect(() => {
    const getStackDates = async () => {
      if (vessel.vesselName && vessel.voyageNumber) {
        const dates = await fetchStackDates(vessel.vesselName, vessel.voyageNumber);
        setStackDates(dates);
      }
    };
    getStackDates();
  }, [vessel.vesselName, vessel.voyageNumber]);

  return (
    <div className="relative group">
      <div className="px-2 py-1 font-medium text-gray-900 dark:text-white">
        {vessel.vesselName || vessel.name}<br/>{vessel.voyageNumber || vessel.voyage}
      </div>
      {stackDates && stackDates.length > 0 && (
        <div className="absolute hidden group-hover:block z-50 p-2 bg-white dark:bg-gray-800 shadow-lg rounded-lg mt-1 right-0 min-w-[200px]">
          <div className="text-sm">
            <div className="font-medium mb-1 text-gray-900 dark:text-white">Reefer Stack Dates:</div>
            {stackDates.map((date, idx) => (
              <div key={idx} className="mb-2 last:mb-0">
                <div className="font-medium text-gray-700 dark:text-gray-300">{date.terminal.replace(/_/g, ' ')}</div>
                <div className="text-gray-600 dark:text-gray-400">
                  Opening: {new Date(date.stack_opening).toLocaleDateString()}
                </div>
                <div className="text-gray-600 dark:text-gray-400">
                  Closing: {new Date(date.stack_closing).toLocaleDateString()}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const ScheduleGrid = ({ vessels, loadPorts, dischargePorts, VesselHeaderComponent }) => (
  <div className="bg-white dark:bg-gray-900 rounded-lg shadow-lg w-full">
    <div className="overflow-x-auto">
      <table className="w-full text-sm border-collapse">
        <colgroup>
          <col className="w-[120px]" />
          {vessels.map((_, idx) => (
            <React.Fragment key={idx}>
              <col className="w-[70px]" />
              <col className="w-[70px]" />
            </React.Fragment>
          ))}
        </colgroup>
        <thead>
          <tr>
            <th className="px-3 py-2 text-left border-b border-r dark:border-gray-600 bg-gray-50 dark:bg-gray-800 font-medium sticky left-0 text-gray-900 dark:text-gray-100">Ports</th>
            {vessels.map((vessel, idx) => (
              <th key={idx} colSpan="2" className="text-center border-b border-r dark:border-gray-600 bg-gray-50 dark:bg-gray-800">
                {VesselHeaderComponent ? (
                  <VesselHeaderComponent 
                    vessel={vessel} 
                    dischargePorts={dischargePorts}
                    vesselIndex={idx} 
                  />
                ) : (
                  <DefaultVesselHeader vessel={vessel} />
                )}
              </th>
            ))}
          </tr>
          <tr>
            <th className="px-3 py-2 text-left border-b border-r dark:border-gray-600 bg-gray-50 dark:bg-gray-800 font-medium sticky left-0 text-gray-900 dark:text-gray-100"></th>
            {vessels.map((_, idx) => (
              <React.Fragment key={idx}>
                <th className="px-2 py-1 border-b border-r dark:border-gray-600 text-xs font-medium text-gray-600 dark:text-gray-300 bg-gray-50 dark:bg-gray-800">ETA</th>
                <th className="px-2 py-1 border-b border-r dark:border-gray-600 text-xs font-medium text-gray-600 dark:text-gray-300 bg-gray-50 dark:bg-gray-800">ETD</th>
              </React.Fragment>
            ))}
          </tr>
        </thead>
        <tbody className="text-xs">
          <tr>
            <td colSpan={vessels.length * 2 + 1} className="px-3 py-2 text-blue-600 dark:text-blue-400 font-medium bg-blue-50 dark:bg-blue-900/20 border-b dark:border-gray-600">
              Load Ports
            </td>
          </tr>
          {loadPorts.map((port, portIdx) => (
            <tr key={portIdx} className="border-b dark:border-gray-600 hover:bg-gray-50 dark:hover:bg-gray-700/50">
              <td className="px-3 py-2 border-r dark:border-gray-600 font-medium sticky left-0 bg-white dark:bg-gray-800 text-gray-900 dark:text-white">{port.name}</td>
              {port.schedules.map((schedule, schedIdx) => (
                <React.Fragment key={schedIdx}>
                  <ScheduleDateCell dateInfo={schedule.eta} />
                  <ScheduleDateCell dateInfo={schedule.etd} />
                </React.Fragment>
              ))}
            </tr>
          ))}
          <tr>
            <td colSpan={vessels.length * 2 + 1} className="px-3 py-2 text-green-600 dark:text-green-400 font-medium bg-green-50 dark:bg-green-900/20 border-b dark:border-gray-600">
              Discharge Ports
            </td>
          </tr>
          {dischargePorts.map((port, portIdx) => (
            <tr key={portIdx} className="border-b dark:border-gray-600 hover:bg-gray-50 dark:hover:bg-gray-700/50">
              <td className="px-3 py-2 border-r dark:border-gray-600 font-medium sticky left-0 bg-white dark:bg-gray-800 text-gray-900 dark:text-white">{port.name}</td>
              {port.schedules.map((schedule, schedIdx) => (
                <React.Fragment key={schedIdx}>
                  <ScheduleDateCell dateInfo={schedule.eta} />
                  <ScheduleDateCell dateInfo={schedule.etd} />
                </React.Fragment>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
);

export default ScheduleGrid;