import { ScheduleCache } from './ScheduleCache';

const API_KEY = process.env.REACT_APP_MAERSK_API_KEY;
const API_BASE_URL = 'https://api.maersk.com/schedules';
const SERVICE_CODE = '040';
const CARRIER_CODE = 'MAEU';
const CACHE_KEY = 'AMEX';
const VESSELS_PER_GRID = 8;

// Port configurations
const PORTS = {
  DURBAN: { name: 'Durban', terminalCode: 'ZADURP1', countryCode: 'ZA', cityName: 'Durban', UNLocationCode: 'ZADUR' },
  CAPE_TOWN: { name: 'Cape Town', terminalCode: 'ZACPT04', countryCode: 'ZA', cityName: 'Cape Town', UNLocationCode: 'ZACPT' },
  NEWARK: { name: 'Newark', terminalCode: 'USNWKTM', countryCode: 'US', cityName: 'Newark', UNLocationCode: 'USEWR' }
};

// Helper function to add delay between API calls
const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

// Helper function to handle API rate limiting
const rateLimitedFetch = async (url, options, retryCount = 3) => {
  try {
    const response = await fetch(url, {
      ...options,
      headers: {
        'Consumer-Key': API_KEY,
        'Accept': 'application/json',
        ...options?.headers
      }
    });

    if (response.status === 429 && retryCount > 0) {
      console.log('Rate limit hit, waiting before retry...');
      await delay(1000); // Wait 1 second before retry
      return rateLimitedFetch(url, options, retryCount - 1);
    }

    if (!response.ok) {
      throw new Error(`API error: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error('Fetch error:', error);
    if (retryCount > 0) {
      console.log(`Retrying... ${retryCount} attempts left`);
      await delay(1000);
      return rateLimitedFetch(url, options, retryCount - 1);
    }
    throw error;
  }
};

// Request queue implementation
const requestQueue = [];
let isProcessing = false;

const processQueue = async () => {
  if (isProcessing || requestQueue.length === 0) return;
  
  isProcessing = true;
  const { url, options, resolve, reject } = requestQueue.shift();
  
  try {
    const response = await rateLimitedFetch(url, options);
    resolve(response);
  } catch (error) {
    reject(error);
  } finally {
    isProcessing = false;
    setTimeout(() => processQueue(), 1000); // Process next request after 1s
  }
};

const queuedFetch = (url, options) => {
  return new Promise((resolve, reject) => {
    requestQueue.push({ url, options, resolve, reject });
    processQueue();
  });
};

// Helper function to format date
const formatDate = (dateTimeStr, isActual = false) => {
  if (!dateTimeStr) return { text: 'N/A', isActual: false, rawDateTime: null };
  const date = new Date(dateTimeStr);
  return {
    text: date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    }),
    isActual,
    rawDateTime: dateTimeStr
  };
};

const transformScheduleData = (vesselSchedules, config) => {
  const { loadPorts, dischargePorts, serviceCode } = config;
  
  // Constants
  const VESSELS_PER_GRID = 8;

  // Filter for AMEX service vessels based on service code 040 and outbound legs only
  const serviceVessels = vesselSchedules.filter(schedule => {
    // Check if this vessel has any AMEX service calls
    const hasAmexService = schedule.vesselCalls?.some(call =>
      call.transport?.outboundService?.carrierServiceCode === serviceCode
    );

    if (!hasAmexService) {
      console.log('Vessel filtered out - not AMEX service:', schedule.vessel?.vesselName);
      return false;
    }

    return true;
  });

  // Extract and format vessel data with outbound voyage numbers only
  const vessels = serviceVessels.map(schedule => {
    // Get the voyage number from the first vessel call
    const voyageNumber = schedule.vesselCalls?.[0]?.transport?.outboundService?.carrierVoyageNumber || '';
    
    // Get the earliest date from any port call
    const earliestDate = schedule.vesselCalls?.reduce((earliest, call) => {
      const arrivalDate = call.callSchedules?.find(s => s.transportEventTypeCode === 'ARRI')?.classifierDateTime;
      if (!arrivalDate) return earliest;
      if (!earliest || new Date(arrivalDate) < new Date(earliest)) return arrivalDate;
      return earliest;
    }, null);

    return {
      vesselName: schedule.vessel.vesselName,
      vesselIMO: schedule.vessel.vesselIMONumber,
      voyageNumber: voyageNumber,
      sortDate: earliestDate || '9999-12-31' // Use far future date if no dates available
    };
  });

  // Sort vessels by voyage number (numerically)
  vessels.sort((a, b) => {
    const voyageA = parseInt(a.voyageNumber.replace(/\D/g, '')) || 0;
    const voyageB = parseInt(b.voyageNumber.replace(/\D/g, '')) || 0;
    return voyageA - voyageB;
  });

  // Ensure we have enough slots for 8 vessels per grid
  while (vessels.length < VESSELS_PER_GRID * 2) {
    vessels.push({
      vesselName: 'TBN',
      vesselIMO: '',
      voyageNumber: ''
    });
  }

  // Split into current and upcoming rotations (8 vessels each)
  const currentVessels = vessels.slice(0, VESSELS_PER_GRID);
  const upcomingVessels = vessels.slice(VESSELS_PER_GRID, VESSELS_PER_GRID * 2);

  // Format port schedules with consistent date format
  const formatPortSchedules = (ports, vesselList, schedules) => {
    return ports.map(port => ({
      name: port.name,
      schedules: vesselList.map(vessel => {
        const schedule = schedules.find(s => s.vessel?.vesselIMONumber === vessel.vesselIMO);
        if (!schedule) return {
          eta: { text: 'N/A', isActual: false, rawDateTime: null },
          etd: { text: 'N/A', isActual: false, rawDateTime: null },
          lastPackoutDay: { text: 'N/A', isActual: false, rawDateTime: null }
        };

        // Find the port call for this vessel and port
        const portCall = schedule.vesselCalls?.find(call => {
          const matchesTerminal = call.facility?.carrierTerminalCode === port.terminalCode;
          const matchesVoyage = call.transport?.outboundService?.carrierVoyageNumber === vessel.voyageNumber;
          const isAmexService = call.transport?.outboundService?.carrierServiceCode === SERVICE_CODE;
          
          if (matchesTerminal && matchesVoyage && isAmexService) {
            console.log('Found matching port call:', {
              vessel: vessel.vesselName,
              voyage: vessel.voyageNumber,
              port: port.name,
              terminal: port.terminalCode,
              schedules: call.callSchedules
            });
          }
          
          return matchesTerminal && matchesVoyage && isAmexService;
        });

        if (!portCall) return {
          eta: { text: 'N/A', isActual: false, rawDateTime: null },
          etd: { text: 'N/A', isActual: false, rawDateTime: null },
          lastPackoutDay: { text: 'N/A', isActual: false, rawDateTime: null }
        };

        const arrival = portCall.callSchedules?.find(s => s.transportEventTypeCode === 'ARRI');
        const departure = portCall.callSchedules?.find(s => s.transportEventTypeCode === 'DEPA');

        // Format dates using dateTime or classifierDateTime
        const arrivalDate = formatDate(
          arrival?.dateTime || arrival?.classifierDateTime,
          arrival?.eventClassifierCode
        );

        const departureDate = formatDate(
          departure?.dateTime || departure?.classifierDateTime,
          departure?.eventClassifierCode
        );

        // Calculate last packout day only if we have a valid arrival date
        let lastPackoutDay = { text: 'N/A', isActual: false, rawDateTime: null };
        if (arrivalDate.rawDateTime) {
          const arrivalDateTime = new Date(arrivalDate.rawDateTime);
          const lastPackoutDateTime = new Date(arrivalDateTime.getTime() - (23 * 24 * 60 * 60 * 1000));
          lastPackoutDay = formatDate(lastPackoutDateTime.toISOString(), false);
          
          console.log('Last Packout Day Calculation:', {
            vessel: vessel.vesselName,
            port: port.name,
            arrivalDate: arrivalDate.rawDateTime,
            lastPackoutDay: lastPackoutDay.rawDateTime
          });
        }

        return {
          eta: arrivalDate,
          etd: departureDate,
          lastPackoutDay
        };
      })
    }));
  };

  return {
    current: {
      vessels: currentVessels,
      loadPorts: formatPortSchedules(loadPorts, currentVessels, serviceVessels),
      dischargePorts: formatPortSchedules(dischargePorts, currentVessels, serviceVessels)
    },
    upcoming: {
      vessels: upcomingVessels,
      loadPorts: formatPortSchedules(loadPorts, upcomingVessels, serviceVessels),
      dischargePorts: formatPortSchedules(dischargePorts, upcomingVessels, serviceVessels)
    }
  };
};

// Helper function to separate rotations
const separateRotations = (vesselSchedules) => {
  // Sort all vessels by departure time
  const sortedSchedules = vesselSchedules.sort((a, b) => new Date(a.departureTime) - new Date(b.departureTime));

  return {
    currentRotation: sortedSchedules.slice(0, 8).map(vs => vs.vessel),
    upcomingRotation: sortedSchedules.slice(8, 16).map(vs => vs.vessel)
  };
};

// Helper to get date range
const getDateRange = () => {
  const today = new Date();
  const startDate = new Date(today);
  startDate.setDate(today.getDate() - 31); // Month back
  return {
    startDate: startDate.toISOString().split('T')[0],
    dateRange: 'P24W'  // Extended to 24 weeks
  };
};

const getPortCalls = async (port, dateParams) => {
  return queuedFetch(
    `${API_BASE_URL}/port-calls?countryCode=${port.countryCode}&cityName=${port.cityName}&carrierCodes=${CARRIER_CODE}&startDate=${dateParams.startDate}&dateRange=${dateParams.dateRange}`
  );
};

export const fetchAmexSchedules = async () => {
  try {
    const dateParams = getDateRange();
    
    // Fetch port calls for all ports
    const [durbanData, capeTownData, newarkData] = await Promise.all([
      queuedFetch(`${API_BASE_URL}/port-calls?countryCode=ZA&cityName=Durban&carrierCodes=${CARRIER_CODE}&startDate=${dateParams.startDate}&dateRange=${dateParams.dateRange}`),
      queuedFetch(`${API_BASE_URL}/port-calls?countryCode=ZA&cityName=Cape Town&carrierCodes=${CARRIER_CODE}&startDate=${dateParams.startDate}&dateRange=${dateParams.dateRange}`),
      queuedFetch(`${API_BASE_URL}/port-calls?countryCode=US&cityName=Newark&carrierCodes=${CARRIER_CODE}&startDate=${dateParams.startDate}&dateRange=${dateParams.dateRange}`)
    ]);

    // Get vessel list from Cape Town calls (like SAECS does)
    const vesselSchedules = [];
    if (capeTownData?.portCalls) {
      capeTownData.portCalls.forEach(portCall => {
        portCall.facilityCalls.forEach(facilityCall => {
          const transport = facilityCall.transport;
          if (transport?.outboundService?.carrierServiceCode === SERVICE_CODE) {
            const departure = facilityCall.callSchedules.find(s => s.transportEventTypeCode === 'DEPA');
            if (departure) {
              vesselSchedules.push({
                vessel: {
                  name: transport.vessel.vesselName,
                  voyage: transport.outboundService.carrierVoyageNumber,
                  code: transport.vessel.carrierVesselCode,
                  vesselIMO: transport.vessel.vesselIMONumber,
                  displayName: `${transport.vessel.vesselName} ${transport.outboundService.carrierVoyageNumber}`
                },
                departureTime: new Date(departure.classifierDateTime)
              });
            }
          }
        });
      });
    }

    // Sort and split into current and upcoming rotations
    const { currentRotation, upcomingRotation } = separateRotations(vesselSchedules);

    // Process port schedules
    const currentLoadPorts = [
      { name: 'Durban', schedules: processPortSchedules(durbanData, currentRotation) },
      { name: 'Cape Town', schedules: processPortSchedules(capeTownData, currentRotation) }
    ];

    const currentDischargePorts = [
      { name: 'Newark', schedules: processPortSchedules(newarkData, currentRotation) }
    ];

    const upcomingLoadPorts = [
      { name: 'Durban', schedules: processPortSchedules(durbanData, upcomingRotation) },
      { name: 'Cape Town', schedules: processPortSchedules(capeTownData, upcomingRotation) }
    ];

    const upcomingDischargePorts = [
      { name: 'Newark', schedules: processPortSchedules(newarkData, upcomingRotation) }
    ];

    return {
      current: {
        vessels: currentRotation.map(v => ({ ...v, displayName: v.displayName || `${v.name} ${v.voyage}` })),
        loadPorts: currentLoadPorts,
        dischargePorts: currentDischargePorts
      },
      upcoming: {
        vessels: upcomingRotation.map(v => ({ ...v, displayName: v.displayName || `${v.name} ${v.voyage}` })),
        loadPorts: upcomingLoadPorts,
        dischargePorts: upcomingDischargePorts
      }
    };
  } catch (error) {
    console.error('Error fetching AMEX schedules:', error);
    throw error;
  }
};

// Helper function to process port schedules (like SAECS)
const processPortSchedules = (portData, vessels) => {
  const schedules = vessels.map(() => ({ 
    eta: { text: 'N/A', isActual: false, rawDateTime: null }, 
    etd: { text: 'N/A', isActual: false, rawDateTime: null },
    lastPackoutDay: { text: 'N/A', isActual: false, rawDateTime: null }
  }));

  if (!portData?.portCalls) return schedules;

  portData.portCalls.forEach(portCall => {
    portCall.facilityCalls.forEach(facilityCall => {
      const transport = facilityCall.transport;
      const vesselIndex = vessels.findIndex(v => {
        // Match both vessel name and voyage number
        const matchesVessel = v.name === transport.vessel.vesselName;
        const matchesVoyage = 
          transport.outboundService?.carrierVoyageNumber === v.voyage ||
          transport.inboundService?.carrierVoyageNumber === v.voyage;
        return matchesVessel && matchesVoyage;
      });
      
      if (vesselIndex !== -1 && 
          (transport.outboundService?.carrierServiceCode === SERVICE_CODE)) {
        
        const arrival = facilityCall.callSchedules.find(s => s.transportEventTypeCode === 'ARRI');
        const departure = facilityCall.callSchedules.find(s => s.transportEventTypeCode === 'DEPA');
        
        const arrivalDate = formatDate(arrival?.dateTime || arrival?.classifierDateTime, arrival?.eventClassifierCode === 'ACT');
        const departureDate = formatDate(departure?.dateTime || departure?.classifierDateTime, departure?.eventClassifierCode === 'ACT');
        
        // Calculate last packout day only if we have a valid arrival date
        let lastPackoutDay = { text: 'N/A', isActual: false, rawDateTime: null };
        if (arrivalDate.rawDateTime) {
          const arrivalDateTime = new Date(arrivalDate.rawDateTime);
          const lastPackoutDateTime = new Date(arrivalDateTime.getTime() - (23 * 24 * 60 * 60 * 1000));
          lastPackoutDay = formatDate(lastPackoutDateTime.toISOString(), false);
          
          console.log('Last Packout Day Calculation:', {
            vessel: vessels[vesselIndex].name,
            port: portData.portName,
            arrivalDate: arrivalDate.rawDateTime,
            lastPackoutDay: lastPackoutDay.rawDateTime
          });
        }

        schedules[vesselIndex] = {
          eta: arrivalDate,
          etd: departureDate,
          lastPackoutDay
        };
      }
    });
  });

  return schedules;
};