import React, { useState, useEffect } from 'react';
import { fetchAmexSchedules } from '../services/AmexApi';
import ExcelExport from './ExcelExport';
import RefreshButton from './RefreshButton';
import LoadingIndicator from './LoadingIndicator';
import { ScheduleCache } from '../services/ScheduleCache';
import ScheduleGrid from './ScheduleGrid';

const VESSELS_PER_GRID = 8;

const formatDateWithTime = (date) => {
  if (!date) return 'N/A';
  const dateObj = new Date(date);
  return dateObj.toLocaleString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  });
};

const getTerminalShortName = (terminal) => {
  if (!terminal) return '';
  const normalizedTerminal = terminal.replace(/_/g, ' ');
  
  if (normalizedTerminal.includes('DURBAN CONTAINER')) return 'DCT';
  if (normalizedTerminal.includes('CAPE TOWN CONTAINER')) return 'CTCT';
  if (normalizedTerminal.includes('NEWARK CONTAINER')) return 'NEWARK';
  return terminal;
};

const VesselHeader = ({ vessel, dischargePorts, vesselIndex }) => {
  if (!vessel) return null;

  // Find Newark schedule from discharge ports
  const newarkPort = dischargePorts?.find(p => p.name === 'Newark');
  const newarkSchedule = newarkPort?.schedules[vesselIndex];
  const etaNewark = newarkSchedule?.eta?.rawDateTime;
  const lastPackoutDay = newarkSchedule?.lastPackoutDay?.rawDateTime;

  console.log('VesselHeader Props:', {
    vessel,
    dischargePorts,
    vesselIndex,
    newarkPort,
    newarkSchedule,
    etaNewark,
    lastPackoutDay
  });

  return (
    <div className="relative group">
      <div className="px-2 py-1 font-medium text-gray-900 dark:text-white group-hover:bg-gray-700/50 rounded transition-colors duration-150">
        {vessel.name}<br/>{vessel.voyage}
      </div>

      {newarkSchedule?.eta?.rawDateTime && (
        <div className="absolute hidden group-hover:block z-50 p-4 bg-white dark:bg-gray-800 shadow-lg rounded-lg mt-1 transform -translate-x-1/4 min-w-[300px] max-w-[350px] left-1/2">
          <div className="relative">
            {/* Arrow pointer */}
            <div className="absolute -top-2 left-1/4 transform -translate-x-1/2 w-4 h-4 rotate-45 bg-white dark:bg-gray-800"></div>
            
            <div className="text-sm">
              {/* Newark Schedule Section */}
              <div className="mb-4">
                <div className="font-medium text-gray-900 dark:text-white mb-2 text-base">Newark Schedule:</div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="bg-gray-50 dark:bg-gray-700 p-3 rounded">
                    <div className="font-medium text-gray-700 dark:text-gray-300 mb-1">ETA:</div>
                    <div className="text-gray-900 dark:text-white">
                      {etaNewark ? formatDateWithTime(etaNewark) : 'N/A'}
                    </div>
                  </div>
                  <div className="bg-gray-50 dark:bg-gray-700 p-3 rounded">
                    <div className="font-medium text-gray-700 dark:text-gray-300 mb-1">Last Packout Day:</div>
                    <div className="text-gray-900 dark:text-white">
                      {lastPackoutDay ? formatDateWithTime(lastPackoutDay) : 'N/A'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const AmexScheduleView = () => {
  const [scheduleData, setScheduleData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  const loadScheduleData = async (clearCache = true) => {
    try {
      setIsLoading(true);

      if (!clearCache) {
        const cachedData = ScheduleCache.getCache('AMEX');
        if (cachedData) {
          setScheduleData(cachedData);
          setError(null);
          setIsLoading(false);
          return;
        }
      } else {
        ScheduleCache.clearCache('AMEX');
      }

      const data = await fetchAmexSchedules();

      if (data.current && data.current.vessels) {
        data.current.vessels = data.current.vessels.slice(0, VESSELS_PER_GRID);
        data.current.loadPorts = data.current.loadPorts.map(port => ({
          ...port,
          schedules: port.schedules.slice(0, VESSELS_PER_GRID)
        }));
        data.current.dischargePorts = data.current.dischargePorts.map(port => ({
          ...port,
          schedules: port.schedules.slice(0, VESSELS_PER_GRID)
        }));
      }

      if (data.upcoming && data.upcoming.vessels) {
        data.upcoming.vessels = data.upcoming.vessels.slice(0, VESSELS_PER_GRID);
        data.upcoming.loadPorts = data.upcoming.loadPorts.map(port => ({
          ...port,
          schedules: port.schedules.slice(0, VESSELS_PER_GRID)
        }));
        data.upcoming.dischargePorts = data.upcoming.dischargePorts.map(port => ({
          ...port,
          schedules: port.schedules.slice(0, VESSELS_PER_GRID)
        }));
      }

      ScheduleCache.setCache('AMEX', data);
      setScheduleData(data);
      setError(null);
    } catch (error) {
      console.error('Error loading schedule data:', error);
      setError('Failed to load schedule data. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadScheduleData(false);
  }, []);

  if (isLoading) return <LoadingIndicator />;
  if (error) return (
    <div className="flex justify-center items-center h-64">
      <div className="text-red-600 dark:text-red-400">{error}</div>
    </div>
  );
  if (!scheduleData) return (
    <div className="flex justify-center items-center h-64">
      <div className="text-gray-600 dark:text-gray-300">No schedule data available</div>
    </div>
  );

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold text-white">AMEX Service Schedule</h1>
        <div className="flex gap-2">
          <RefreshButton onRefresh={() => loadScheduleData(true)} serviceName="AMEX" />
          <ExcelExport data={scheduleData} serviceName="AMEX" />
        </div>
      </div>

      <div className="space-y-8">
        {/* Primary Schedule */}
        <div>
          <h2 className="text-lg font-semibold text-blue-400 mb-4">Primary Schedule</h2>
          <ScheduleGrid
            vessels={scheduleData.current.vessels}
            loadPorts={scheduleData.current.loadPorts}
            dischargePorts={scheduleData.current.dischargePorts}
            VesselHeaderComponent={VesselHeader}
          />
        </div>

        {/* Extended Schedule */}
        <div>
          <h2 className="text-lg font-semibold text-green-400 mb-4">Extended Schedule</h2>
          {console.log('Schedule Data:', {
            vessels: scheduleData.upcoming.vessels,
            dischargePorts: scheduleData.upcoming.dischargePorts
          })}
          <ScheduleGrid
            vessels={scheduleData.upcoming.vessels}
            loadPorts={scheduleData.upcoming.loadPorts}
            dischargePorts={scheduleData.upcoming.dischargePorts}
            VesselHeaderComponent={VesselHeader}
          />
        </div>
      </div>
    </div>
  );
};

export default AmexScheduleView;