import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Ship, Search } from 'lucide-react';
import ThemeToggle from './ThemeToggle';

const Navigation = () => {
  const location = useLocation();
  
  return (
    <nav className="bg-white dark:bg-gray-800 shadow transition-colors duration-200">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex items-center justify-between h-14">
          <div className="flex items-center">
            <span className="text-blue-500 dark:text-blue-400 font-bold text-lg mr-8 select-none">
              Maersk Schedules
            </span>
            
            <div className="flex space-x-4">
              <Link
                to="/"
                className={`flex items-center px-3 py-1 text-sm ${
                  location.pathname === '/' 
                    ? 'text-blue-500 dark:text-blue-400 border-b-2 border-blue-500 dark:border-blue-400' 
                    : 'text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-100'
                }`}
              >
                <Ship className="w-4 h-4 mr-2" />
                SAECS Service
              </Link>
              
              <Link
                to="/amex"
                className={`flex items-center px-3 py-1 text-sm ${
                  location.pathname === '/amex'
                    ? 'text-blue-500 dark:text-blue-400 border-b-2 border-blue-500 dark:border-blue-400'
                    : 'text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-100'
                }`}
              >
                <Ship className="w-4 h-4 mr-2" />
                AMEX Service
              </Link>
              
              <Link
                to="/protea"
                className={`flex items-center px-3 py-1 text-sm ${
                  location.pathname === '/protea'
                    ? 'text-blue-500 dark:text-blue-400 border-b-2 border-blue-500 dark:border-blue-400'
                    : 'text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-100'
                }`}
              >
                <Ship className="w-4 h-4 mr-2" />
                Protea Service
              </Link>
              
              <Link
                to="/mesawa"
                className={`flex items-center px-3 py-1 text-sm ${
                  location.pathname === '/mesawa'
                    ? 'text-blue-500 dark:text-blue-400 border-b-2 border-blue-500 dark:border-blue-400'
                    : 'text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-100'
                }`}
              >
                <Ship className="w-4 h-4 mr-2" />
                Mesawa Service
              </Link>

              <Link
                to="/search"
                className={`flex items-center px-3 py-1 text-sm ${
                  location.pathname === '/search'
                    ? 'text-blue-500 dark:text-blue-400 border-b-2 border-blue-500 dark:border-blue-400'
                    : 'text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-100'
                }`}
              >
                <Search className="w-4 h-4 mr-2" />
                Vessel Search
              </Link>
            </div>
          </div>
          
          <div className="flex items-center">
            <ThemeToggle />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;