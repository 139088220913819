import React from 'react';
import { Ship, AlertCircle } from 'lucide-react';

const VesselSearch = () => {
  return (
    <div className="max-w-4xl mx-auto p-4">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8 text-center">
        <div className="flex justify-center mb-6">
          <Ship className="h-16 w-16 text-blue-500 dark:text-blue-400" />
        </div>
        
        <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
          Vessel Schedules
        </h1>
        
        <div className="flex items-center justify-center gap-2 mb-6">
          <AlertCircle className="h-5 w-5 text-blue-500 dark:text-blue-400" />
          <span className="text-lg text-blue-500 dark:text-blue-400 font-medium">
            Future Feature
          </span>
        </div>
        
        <p className="text-gray-600 dark:text-gray-300 mb-8 max-w-2xl mx-auto">
          The ability to search for specific vessels and view their schedules will be available in a future update. 
          For now, please use our service-specific schedule views to track vessels on their respective routes.
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 max-w-2xl mx-auto">
          <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
            <h3 className="font-semibold text-gray-900 dark:text-white mb-2">Available Now</h3>
            <ul className="text-gray-600 dark:text-gray-300 text-sm space-y-2">
              <li>• SAECS Service Schedules</li>
              <li>• AMEX Service Schedules</li>
              <li>• Protea Service Schedules</li>
              <li>• Mesawa Service Schedules</li>
            </ul>
          </div>
          
          <div className="p-4 bg-blue-50 dark:bg-blue-900/20 rounded-lg">
            <h3 className="font-semibold text-gray-900 dark:text-white mb-2">Coming Soon</h3>
            <ul className="text-gray-600 dark:text-gray-300 text-sm space-y-2">
              <li>• Vessel Search by Name</li>
              <li>• Vessel Schedule Tracking</li>
              <li>• Real-time Updates</li>
              <li>• Schedule Notifications</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VesselSearch;